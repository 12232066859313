import React from "react";
import { Button } from 'reactstrap';

import Layout from "../components/layout";
import SEO from "../components/seo";


export default function IndexPage() {
    return <Layout>
        <SEO title="About" />

        <h2>Main skills</h2>

        <ul>
            <li>
                <strong>Python:</strong> Django, Flask, SQLAlchemy, Pytest, ...
            </li>
            <li>
                <strong>JavaScript{':'}</strong> React, Apollo Client, Gatsby, Webpack, ...
            </li>
            <li>
                <strong>Web services:</strong> REST, GraphQL
            </li>
            <li>
                <strong>Web:</strong> HTML5, CSS3, SCSS
            </li>
            <li>
                <strong>Databases:</strong> PostgreSQL, PostGIS, Redis
            </li>
            <li>
                <strong>Version control:</strong> GIT
            </li>
            <li>
                <strong>Services:</strong> AWS, Heroku, CloudFlare, Netlify, ...
            </li>
            <li>Agile software development (Kanban)</li>
        </ul>

        <h2>Curriculum Vitae</h2>

        <Button color="primary" tag="a" href="mailto:samuele@samuelesanti.com?subject=CV Request">
            Ask for my CV
        </Button>

    </Layout>;
}
